import {React} from "react"
import { graphql } from "gatsby"
import {sanitize} from "dompurify"
import Layout from "/src/layout/default"
import Meta from "/src/components/Meta"
import * as newsStyle from "./news.module.css"

const IndexPage = ({data, location}) => {
  return (
    <Layout location={location}>
      <Meta title="お知らせ | 平安堂こころのクリニック" description="平安堂こころのクリニックは、津田沼駅の心療内科・精神科・児童精神科です。専門外来に、子どもの発達外来、強迫症外来を設けています。患者さまに安心して帰っていただけるこころ安らぐクリニックを目指しています。" >
        <meta name="format-detection" content="telephone=no" />
      </ Meta>
      <div className={newsStyle.container}>
        <h1 className="page-title"><span className="page-title-inner">お知らせ</span></h1>
        <ul className={newsStyle.list}>
          {data.allMicrocmsNews.nodes.map(({id,publishedAt,rich_content,title}) => {
            const date = new Date(publishedAt)
            const dateString = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
            const content = typeof window === 'undefined' ? '' : sanitize(rich_content)
            return (
              <li id={ 'news-' + id } className={newsStyle.listItem} key={id}>
                <article>
                  <p className={newsStyle.date}><time dateTime={ publishedAt }>{ dateString }</time></p>
                  <h2 className={newsStyle.articleTitle}>{ title }</h2>
                  <div className={newsStyle.content} dangerouslySetInnerHTML={ {__html: content} } />
                </article>
              </li>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allMicrocmsNews(sort: {fields: publishedAt, order: DESC}) {
      totalCount
      nodes {
        id
        publishedAt
        rich_content
        title
      }
    }
  }
`